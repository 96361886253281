import './App.css';

import React, { useState, useEffect } from 'react';


const App = () => {
  const [weatherData, setWeatherData] = useState(null);
  //const [city, setCity] = useState('London'); // Default city
  const [isLoading, setIsLoading] = useState(false); // New state variable for loading indicator
  const [error, setError] = useState(null); // New state variable for error handling

  console.log(process.env.REACT_APP_WEATHER);

  useEffect(() => {
    // Function to get the user's geolocation
    const getUserLocation = () => {

      setIsLoading(true); // Start loading indicator

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
          const { latitude, longitude } = position.coords;

          // Fetch the city name based on the user's coordinates using a reverse geocoding API
          fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${process.env.REACT_APP_WEATHER}`)
            .then((response) => response.json())
            .then((data) => {
              // Set the city based on the user's location
              //setCity(data.name);
              setWeatherData(data);
            })
            .catch((error) => {
              setError('Error fetching weather data. Please try again later.');
              console.error('Error fetching user location:', error);
            })
            .finally(() => {
              setIsLoading(false); // Stop loading indicator, whether fetch succeeds or fails
            });
        }, 
        (locationError) => {
          if (locationError.code === 1) {
            // Permission denied by the user
            setError('Location access has been denied. Please enable it in your browser settings.');
          } else {
            setError('Error getting your location. Please try again later.');
            console.error('Location error:', locationError);
          }
          setIsLoading(false);
        });
      } else {
        setError('Geolocation is not supported by your browser. Please enable it.');
        setIsLoading(false); // Stop loading
      }
    };

    // Get the user's location when the component mounts
    getUserLocation();
  }, []);

  return (
    <div className="weather">
      <h1>DACCAA Weather</h1>

      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : weatherData ? (
        <div>
          <h2>{weatherData.name}, {weatherData.sys.country}</h2>
          <div className={`owi owi-${weatherData.weather[0].icon}`} />
          <div className="data">
            <p><strong>Weather:</strong> {weatherData.weather[0].description}</p>
            <p><strong>Temperature:</strong> {Math.round(weatherData.main.temp - 273.15)}°C</p>
            <p><strong>Humidity:</strong> {weatherData.main.humidity}%</p>
            <p><strong>Wind:</strong> {weatherData.wind.speed} m/s</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default App;
