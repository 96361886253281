import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Footer from './Footer'; // Import the Footer component  

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="wholeApp">
      <App />
    </div>
    <Footer /> {/* Include the Footer component */}
  </React.StrictMode>
);

