import React from 'react';

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2023 <a href="https://daccaa.com" target="_blank" rel="noreferrer">DACCAA</a>. A simple React project.</p>
    </footer>
  );
};

export default Footer;
